@import 'generalStyle';

.button {
  @include button();
  border-radius: 20px !important;
  padding: 13px 22px !important;
  color: $primaryTextButtonColor;
  font-weight: 700;
  z-index: 100; /* Making sure it is displayed over sticky content/elements */
}

.options {
  a {
    &:global(.exportOptions) {
      color: $secondaryGrayColor !important;
    }
  }
}
